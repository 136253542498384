<template>
  <div>
    <Background :page="page"></Background>
<!--    <coming-soon-->
<!--        mode="light"-->
<!--    ></coming-soon>-->
    <div class="content">
      <div class="contact-list">
        <a href="https://discord.gg/U2B76Jgnds" class="contact-link">
          <div class="contact-brand-icon" :style="{'--bkgImage': 'url(' + require(`@/assets/contact/discord-mark-white.svg`) + ')'}"></div>
          Discord
        </a>

        <a href="https://www.instagram.com/tairyu.project/" class="contact-link">
          <div class="contact-brand-icon" :style="{'--bkgImage': 'url(' + require(`@/assets/contact/Instagram_Glyph_White.svg`) + ')'}"></div>
          Instagram
        </a>

        <div class="contact-link">
          <span style="font-weight: bold">Mail</span> info@tairyu.org
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Background from "@/components/Background";
import ComingSoon from "@/components/ComingSoon";

export default {
  name: "ContactView",
  components: {Background,ComingSoon},
  data() {
    return {
      page: "contact",
    }
  },
}
</script>

<style scoped>

.contact-brand-icon{
  width: 50px;
  height: 50px;
  cursor: pointer;

  background-image: var(--bkgImage);
  background-size:contain;
  background-position:center;
  background-repeat:no-repeat;
}

.content{
  position: fixed;
  top: 70px;
  height: calc(100vh - 70px);
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  z-index: 1;

  overflow: scroll;
  margin-left: auto;
  margin-right: auto;
}

.contact-list{
  width: 90%;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;

  display: flex;
  flex-flow: column;
  align-items: center;
}

.contact-link{
  display: flex;
  flex-flow: column;
  align-items: center;
  text-decoration: none;
  color: white;

  width: 50px;
  height: 50px;

  margin-top: 25px;
  margin-bottom: 25px;
}

</style>