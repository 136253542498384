<template>
  <div class="content">
    mainmainmainmain
    <img id='title-image' src="../assets/top/tairyu_top_visual4.svg" alt="top4" class="piece"/>
    <div class="main-area">

      main
      main
      main

    </div>
  </div>
</template>

<script>
export default {
  name: "MainContent"
}
</script>

<style scoped>
#title-image{
  width: 90%;
}
.content{
  width: 70vw;
  display: flex;
  flex-flow: column;
}
.main-area{
  background: var(--base-gray);
  opacity: 0.6;
  width: 100%;
  height: 200vh;

  border-radius: 26px;
}
</style>