import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NewsView from '../views/NewsView.vue'
import AboutView from '../views/AboutView.vue'
import MemberView from '../views/MemberView.vue'
import WorksView from '../views/WorksView.vue'
import ContactView from '../views/ContactView.vue'
import Item1 from '../views/news-items/Item1.vue'
import Item2 from '../views/news-items/Item2.vue'
import NewsIndex from '../views/news-items/NewsIndex.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/news',
    component: NewsView,
    children: [
      {
        path: 'index',
        component: NewsIndex
      },
      {
        path: 'tamana-02',
        component: Item1
      },
      {
        path: 'tamana-02-arch',
        component: Item2
      },
    ]
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/member',
    name: 'member',
    component: MemberView
  },
  {
    path: '/works',
    name: 'works',
    component: WorksView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
