<template>
  <div id="about">
      <Background :page="page"></Background>
  </div>
  <coming-soon
      mode="light"
  ></coming-soon>
</template>

<script>
import Background from "@/components/Background";
import ComingSoon from "@/components/ComingSoon";
export default {
  name: "AboutView",
  components: {Background, ComingSoon},
  data() {
    return {
      page: "about",
    }
  },
}
</script>

<style scoped>
</style>