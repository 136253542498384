<template>
  <div>
    <NewsCard
        imgId="1"
        path="/news/tamana-02"
        img="tairyu-takase-dm1.png"
        title="TAIRYU, PROJECT Exhibition #2「ふれる」"
        subTitle="2023/1/27-2023/1/29　|　高瀬蔵"
    ></NewsCard>
    <div class="news-section">
      <div class="news-text">
        玉名にふれた3日間<br>
        そこでふれた魅力はわたしたちを何度も連れ戻しました。<br>
        今回のTAIRYU, PROJECTでは、そんな3日間をまとめた観測展を行います。<br>
        目だけでなく耳や鼻も澄ませ、ゆったりのんびり楽しんでください。<br>
        開催期間<br>
        2023.01.27(Fri) - 2023.01.29(Sun)<br>
        Fri｜ 13:00 - 20:00<br>
        Sat & Sun｜10:00 - 18:00<br>
        開催場所<br>
        NPO法人高瀬蔵多目的ホール<br>
        〒865-0025 熊本県玉名市高瀬155-1<br>
        入場無料<br>
        --<br>
        TAIRYU, PROJECT<br>
        Mail｜info@tairyu.org<br>
        Web｜https://tairyu.org<br>
        Discord｜https://discord.gg/U2B76Jgnds<br>
      </div>
    </div>
  </div>
</template>

<script>
import NewsItemTemplate from "@/components/NewsItemTemplate";
import Background from "@/components/Background";
import NewsCard from "@/components/NewsCard";

export default {
  name: "Item1",
  components: {Background, NewsItemTemplate, NewsCard},
}
</script>

<style scoped>
.news-section{
  display: flex;
  flex-flow: column;
  justify-items: center;

  color: black;
  background: var(--base-gray);

  width: 95%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 20px;
  margin-bottom: 30px;
}
.news-text{
  text-align: start;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

</style>