<template>
  <div class="header header-text">
    <a class="header-a" href="/">HOME</a>
    <a class="header-a" href="/news/index">NEWS</a>
    <a class="header-a" href="/about">ABOUT</a>
    <a class="header-a" href="/member">MEMBER</a>
<!--    <a class="header-a" href="/works">WORKS</a>-->
    <a class="header-a" href="/contact">CONTACT</a>
  </div>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>
.header{
  height: 70px;
  width: 100vw;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 1000;

  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center
}
.header-text{
  font-family: transat, sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 24px;
}
.header-a{
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  color: white;
  cursor: pointer;
  position: relative;
}

@media screen and (max-width: 600px) {
  .header-text{
    font-size: 18px;
  }
  .header-a{
    margin-left: 5px;
    margin-right: 5px;
    font-size: 16px;
  }
}


a::after {
  position: absolute;
  left: 0;
  content: '';
  width: 100%;
  height: 1.5px;
  background: white;
  bottom: -1px;
  transform: scale(0, 1);
  transform-origin: center top; /*変形（アンダーラインの伸長）の原点がaタグ（各メニュー）の右端*/
  transition: transform 0.3s;   /*変形の時間*/
}

a:hover::after {
  transform: scale(1.2, 1); /*ホバー後、x軸方向に1（相対値）伸長*/
}

</style>