
import { defineComponent } from 'vue';
import TopVisual from '@/components/TopVisual.vue';
import Background from "@/components/Background.vue";

export default defineComponent({
  name: 'HomeView',
  components: {
    TopVisual,
    Background,
  },
  data() {
    return {
      page: "top",
    }
  },
});
