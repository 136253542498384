<template>
  <div>
    <NewsCard
        imgId="2"
        path="/news/tamana-02-arch"
        img="arch1.jpg"
        title="【開催レポート】TAIRYU, PROJECT Exhibition #2「ふれる」を開催しました。"
        subTitle="2023/1/27-2023/1/29　|　NPO法人高瀬蔵"
    >
    </NewsCard>

    <NewsCard
        imgId="1"
        path="/news/tamana-02"
        img="tairyu-takase-dm1.png"
        title="TAIRYU, PROJECT Exhibition #2「ふれる」"
        subTitle="2023/1/27-2023/1/29　|　NPO法人高瀬蔵"
    >
    </NewsCard>
  </div>
</template>

<script>
import NewsCard from "@/components/NewsCard";
export default {
  name: "NewsIndex",
  components: {NewsCard}
}
</script>

<style scoped>

</style>