<template>
  <div class="top-view">
    <div id="tile">
      <div class="row-piece">
        <img id='top1' src="../assets/top/tairyu_top_visual1.svg" alt="top1" class="piece"/>
        <img id='top2' src="../assets/top/tairyu_top_visual2.svg" alt="top2" class="piece"/>
        <img id='top3' src="../assets/top/tairyu_top_visual3.svg" alt="top3" class="piece"/>
      </div>
      <div class="row-piece">
        <img id='top4' src="../assets/top/tairyu_top_visual4.svg" alt="top4" class="piece"/>
        <img id='top5' src="../assets/top/tairyu_top_visual5.svg" alt="top5" class="piece"/>
        <img id='top6' src="../assets/top/tairyu_top_visual6.svg" alt="top6" class="piece"/>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "TopVisual"
}
</script>

<style scoped>
.top-view{
  height: 100vh;
  width: 100vw;
  background: black;
  display: flex;
  align-items: center
}
.piece{
  width: calc(100vw/3);
}
.row-piece{
  display: flex;
  flex-flow: row;
}

#tile{
  animation: flash 1.5s normal infinite;
  animation-delay: 2.5s;
}

#top1{
  animation: anim1 2s;
  animation-fill-mode:forwards;
}
@keyframes anim1 {
  0% {
    transform: translate(0px);
  }
  50% {
    transform: translateX(calc(100vw/3));
  }
  100% {
    transform: translate(calc(100vw/3),calc(100vw/3));
  }
}

#top2{
  animation: anim2 2s;
  animation-fill-mode:forwards;
}
@keyframes anim2 {
  0% {
    transform: translate(0px);
  }
  50% {
    transform: translateY(calc(100vw/3));
  }
  100% {
    transform: translate(calc(100vw/3),calc(100vw/3));
  }
}

#top3{
  animation: anim3 2s;
  animation-fill-mode:forwards;
}
@keyframes anim3 {
  0% {
    transform: translate(0px);
  }
  33% {
    transform: translateX(calc(-100vw/3));
  }
  66% {
    transform: translateX(calc(-2*100vw/3));
  }
  100% {
    transform: translate(calc(-2*100vw/3),calc(100vw/3));
  }
}

#top4{
  animation: anim4 2s;
  animation-fill-mode:forwards;
}
@keyframes anim4 {
  0% {
    transform: translate(0px);
  }
  33% {
    transform: translateY(calc(-100vw/3));
  }
  66% {
    transform: translateY(calc(-100vw/3));
  }
  100% {
    transform: translate(calc(100vw/3),calc(-100vw/3));
  }
}

#top5{
  animation: anim5 2s;
  animation-fill-mode:forwards;
}
@keyframes anim5 {
  0% {
    transform: translate(0px);
  }
  33% {
    transform: translateY(calc(-100vw/3));
  }
  66% {
    transform: translate(calc(100vw/3),calc(-100vw/3));
  }
  100% {
    transform: translate(calc(100vw/3),calc(-100vw/3));
  }
}

#top6{
  animation: anim6 2s;
  animation-fill-mode:forwards;
}
@keyframes anim6 {
  0% {
    transform: translate(0px);
  }
  33% {
    transform: translateX(calc(-100vw/3));
  }
  66% {
    transform: translateX(calc(-2*100vw/3));
  }
  100% {
    transform: translate(calc(-2*100vw/3),calc(-100vw/3));
  }
}

#message{
  font-family: transat, sans-serif;
  font-weight: 400;
  font-style: italic;

  position: absolute;
  top: 50%;
  width: 100vw;
  text-align: center;
  font-size: 32px;
  opacity: 0;
  animation: anim-msg 0.5s ease-in 2s;
  animation-fill-mode:forwards;
}
@keyframes anim-msg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#dot1{
  animation: flash 1.5s normal infinite;
  animation-delay: 2.5s;
  opacity: 0;
}
#dot2{
  animation: flash 1.5s normal infinite;
  animation-delay: 3s;
  opacity: 0;
}
#dot3{
  animation: flash 1.5s normal infinite;
  animation-delay: 3.5s;
  opacity: 0;
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
</style>