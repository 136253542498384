<template>
  <div>
    <NewsCard
        imgId="2"
        path="/news/tamana-02-arch"
        img="arch1.jpg"
        title="【開催レポート】TAIRYU, PROJECT Exhibition #2「ふれる」を開催しました。"
        subTitle="2023/1/27-2023/1/29　|　NPO法人高瀬蔵"
    >
    </NewsCard>
    <div class="news-section">
      <div class="news-text">
        1/27(金)-1/29(日)の3日間、熊本県玉名市にあります高瀬蔵にて TAIRYU, PROJECT Exhibition #2 「ふれる」を開催しました。<br>

        <div class="content-img-3-2" :style="{'--bkgImage': 'url(' + require(`@/assets/news/2/arch2.jpg`) + ')'}"></div>
        3日間で玉名市内外から、50人ほどの方々にお越しいただきました。ご高覧いただいた皆様、遠いところ、また雪の舞う寒い中お越しいただきありがとうございました！<br><br>
        29日(日)には相互的な軽いトークセッションも実施、集まっていただいた方々と今回の展示内容を通して玉名を深掘るコミュニケーションの場となりました。<br>
        <div class="content-img-3-2" :style="{'--bkgImage': 'url(' + require(`@/assets/news/2/arch5.jpg`) + ')'}"></div>
        今回の展示は、昨年夏よりTAIRYUが玉名にて行ってきたリサーチ結果が主な内容。玉名の持つ自然や文化の資源を、私たちなりの視点から調査しています。各所巡る中での玉名地域の自然資源のコンパクトさや、街歩きを行う中での昭和チックな空気感、その体験は私たちに多くの「気づき」をもたらしました。<br><br>
        逆にそこから展示作り出す過程では、普段玉名の中にいる方々に、その資源について捉え直すような「気づき」をもたらし、玉名のアイデンティティを見つける足がかりを目指して展示を構築。<br><br>
        今回の展示を通して、少しでも皆様が玉名への捉え方を変化させる機会をTAIRYUが作り出せたのであれば幸いです。<br>
        <div class="content-img-2-3" :style="{'--bkgImage': 'url(' + require(`@/assets/news/2/arch3.jpg`) + ')'}"></div>
        引き続き、TAIRYU, PROJECTは玉名での活動も行い、私たちの立ち位置から玉名を見つめ、関わって参ります。<br><br>
        今後ともどうぞTAIRYU, PROJECTをよろしくお願いいたします。

      </div>


      <div class="end-img-3-2" :style="{'--bkgImage': 'url(' + require(`@/assets/news/2/arch4.jpg`) + ')'}"></div>
      <div class="end-img-3-2" :style="{'--bkgImage': 'url(' + require(`@/assets/news/2/arch6.jpg`) + ')'}"></div>
      <div class="end-img-3-2" :style="{'--bkgImage': 'url(' + require(`@/assets/news/2/arch7.jpg`) + ')'}"></div>
      <div class="end-img-3-2" :style="{'--bkgImage': 'url(' + require(`@/assets/news/2/arch8.jpg`) + ')'}"></div>
      <div class="end-img-3-2" :style="{'--bkgImage': 'url(' + require(`@/assets/news/2/arch9.jpg`) + ')'}"></div>

    </div>

    <div class="related-info-title">
      開催情報
    </div>
    <NewsCard
        imgId="1"
        path="/news/tamana-02"
        img="tairyu-takase-dm1.png"
        title="TAIRYU, PROJECT Exhibition #2「ふれる」"
        subTitle="2023/1/27-2023/1/29　|　高瀬蔵"
    ></NewsCard>
  </div>
</template>

<script>
import NewsItemTemplate from "@/components/NewsItemTemplate";
import Background from "@/components/Background";
import NewsCard from "@/components/NewsCard";

export default {
  name: "Item1",
  components: {Background, NewsItemTemplate, NewsCard},
}
</script>

<style scoped>
.news-section{
  display: flex;
  flex-flow: column;
  justify-items: center;

  color: black;
  background: var(--base-gray);

  width: 95%;
  margin-right: auto;
  margin-left: auto;
  border-radius: 20px;
  margin-bottom: 30px;
}
.news-text{
  text-align: start;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}
.related-info-title{
  font-size: 28px;
}

.content-img-3-2{
  width: 95%;
  aspect-ratio: 3 / 2;
  margin-left: auto;
  margin-right: auto;
  background-image: var(--bkgImage);
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;

  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.content-img-2-3{
  width: 95%;
  aspect-ratio: 2 / 3;
  margin-left: auto;
  margin-right: auto;
  background-image: var(--bkgImage);
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;

  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.end-img-3-2{
  width: 75%;
  aspect-ratio: 3 / 2;
  margin-left: auto;
  margin-right: auto;
  background-image: var(--bkgImage);
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;

  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
}

</style>