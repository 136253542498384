<template>
  <div id="news">
    <Background :page="page"></Background>
    <div class="content">
      <img id='title-image' src="../assets/news/title_image.png" alt="top4" class="piece"/>
      <div class="main-area">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Background from "@/components/Background";
import MainContent from "@/components/MainContent";
import ComingSoon from "@/components/ComingSoon";
import NewsCard from "@/components/NewsCard";
export default {
  name: "NewsView",
  components: {NewsCard, Background,MainContent,ComingSoon},
  data() {
    return {
      page: "news",
    }
  },
}
</script>

<style scoped>
#title-image{
  width: 90%;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.content{
  position: fixed;
  z-index: 1;
  top: 70px;
  padding-left: 15vw;
  padding-right: 15vw;
  height: calc(100vh - 70px);
  /*padding-left: 15vw;*/
  /*padding-right: 15vw;*/
  /*display: flex;*/
  /*flex-flow: column;*/

  overflow: scroll;

  margin-left: auto;
  margin-right: auto;
}
.main-area{
  padding-top: 30px;
  display: flex;
  flex-flow: column;
  justify-items: center;

  color: black;
  background: var(--base-gray);
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;

  border-radius: 26px;
  margin-bottom: 40px;
}

@media screen and (max-width: 600px) {
  .content{
    padding-left: 0;
    padding-right: 0;
  }
}

</style>