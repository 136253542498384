<template>
  <div>
    <div id="message" :class="mode">COMING SOON <span id="dot1">.</span><span id="dot2">.</span><span id="dot3">.</span></div>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
  props: ["mode"],
}
</script>

<style scoped>
#message{
  font-family: transat, sans-serif;
  font-weight: 400;
  font-style: italic;

  position: absolute;
  top: 50%;
  width: 100vw;
  text-align: center;
  font-size: 32px;
  opacity: 0;
  animation: anim-msg 0.5s;
  animation-fill-mode:forwards;
}
@keyframes anim-msg {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#dot1{
  animation: flash 1.5s normal infinite;
  animation-delay: 0.5s;
  opacity: 0;
}
#dot2{
  animation: flash 1.5s normal infinite;
  animation-delay: 1s;
  opacity: 0;
}
#dot3{
  animation: flash 1.5s normal infinite;
  animation-delay: 1.5s;
  opacity: 0;
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.dark{
  color: black;
}

.light{
  color: white;
}
</style>