<template>
  <div>
    <Background :page="page"></Background>
    <coming-soon
        mode="light"
    ></coming-soon>
  </div>
</template>

<script>
import Background from "@/components/Background";
import ComingSoon from "@/components/ComingSoon";
export default {
  name: "MemberView",
  components: {Background,ComingSoon},
  data() {
    return {
      page: "member",
    }
  },
}
</script>

<style scoped>

</style>