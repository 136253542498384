<template>
<!--  <nav>-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </nav>-->
  <Header></Header>
  <router-view/>
</template>

<script>
  import Header from "@/components/Header";
  export default {
    components: {Header}
  }

</script>

<style>

* {
  margin: 0;
  padding: 0;
}
body{
  background: black;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
}
:root{
  --base-color: #f1f0f2;
  --main-color: #1b2a83;
  --accent-color1: #f9e24c;
  --accent-color2: #6695d0;
  --text-gray: #989898;
  --base-gray: #cbcbcb;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: white;
}

nav a.router-link-exact-active {
  color: #E69C37;
}
</style>
