<template>
  <div>
    <Background :page="page"></Background>
  </div>
</template>

<script>
import Background from "@/components/Background";

export default {
  name: "WorksView",
  components: {Background},
  data() {
    return {
      page: "works",
    }
  },
}
</script>

<style scoped>

</style>