<template>
  <div class="card" v-on:click="moveToNews(path)">
    <div class="main-img" :style="{'--bkgImage': 'url(' + require(`@/assets/news/${imgId}/${img}`) + ')'}"></div>
    <div class="title">{{title}}</div>
    <div class="sub-title">{{subTitle}}</div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "NewsCard",
  props: {
    imgId: {
      type: String,
      required: true
    },
    path: {
      type: String,
      required: true
    },
    img: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false
    }

  },
  methods:{
    moveToNews(path){
      router.push(path)
      console.log(path)
    }
  }
}

</script>

<style scoped>
.main-img{
  width: 95%;
  aspect-ratio: 3 / 2;
  margin-left: auto;
  margin-right: auto;
  background-image: var(--bkgImage);
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;

  border-radius: 20px;
}
.card{
  margin-bottom: 45px;
  cursor: pointer;
}
.title{
  margin-top: 5px;
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .title{
    font-size: 18px;
  }
}

</style>