import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

createApp(App).use(store).use(router).mount('#app')

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBf5ucuSYQxmwrnxRbMNgG1jZXXAOYLwfA",
    authDomain: "tairyu-website.firebaseapp.com",
    projectId: "tairyu-website",
    storageBucket: "tairyu-website.appspot.com",
    messagingSenderId: "455461562837",
    appId: "1:455461562837:web:b2d90c15448b796716f55c",
    measurementId: "G-1DK6WXG22B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
